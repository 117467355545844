import React from "react"
import { graphql } from "gatsby"

import ArticlesComponent from "../components/articles"
import Layout from "../components/layout"

export const query = graphql`
  query Author($id: Int!) {
    articles: allStrapiArticle(
      filter: { author: { id: { eq: $id } } }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          strapiId
          title
          published_at
          content
          category {
            id
            name
          }
          image {
            publicURL
          }
          author {
            id
            username
          }
        }
      }
    }
    author: strapiUser(strapiId: { eq: $id }) {
      username
    }
  }
`

const Author = ({ data }) => {
  const articles = data.articles.edges
  const author = data.author.username

  return (
    <Layout>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h1>{author}</h1>
          <ArticlesComponent articles={articles} />
        </div>
      </div>
    </Layout>
  )
}

export default Author

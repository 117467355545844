import React from "react"

import Card from "./card"

const Articles = ({ articles }) => {
  return (
    <div className="uk-section">
      <div className="uk-container uk-container-small">
        <ul className="uk-list uk-list-divider uk-link-text">
          {articles.map((article, index) => (
            <li key={index}>
              <Card article={article} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Articles
